import { RegisterResource } from '~/resources/register'

export default defineNuxtRouteMiddleware(async (to) => {
  const slug = to.params.register as string
  const invitation = to.params.invitation as string | undefined
  const { isInvited, setInvitation } = useRegisterInvitation(slug)

  if (isInvited.value) {
    return
  }

  if (!invitation) {
    return navigateTo(`/${slug}`)
  }

  const registerResource = new RegisterResource()
  const registerResponse = await registerResource.getWithInvitation(slug, invitation)

  if (registerResponse.data.value?.item) {
    setInvitation(invitation)
    return navigateTo(`/${slug}`)
  }
  return navigateTo(`/${slug}`)
})
